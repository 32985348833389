<template>
  <div class="nav-container">
    <nav :class="isMenuOpen? 'nav-dropdown-enabled__container':''">
      <div id="logo-container">
        <router-link to="/">
          <img src="../assets/logos/DBHubsTextLogo.png" id="logo-text">
        </router-link>
      </div>
      <button class="menu-toggle" @click="toggleMenu">
        ☰
      </button>
      <div :class="['link-container-wrapper', { 'show-menu': isMenuOpen }]">
        <div class="link-container">
          <router-link class="nav-link hover-color-nav " draggable="false" to="/">Home</router-link>
        </div>
        <div class="separator">|</div>
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/coaches">Coaches</router-link>
        </div>
        <div class="separator">|</div>
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/about">About</router-link>
        </div>
        <div class="separator">|</div>
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/join">Join</router-link>
        </div>
      </div>
      <div class="flex-spacer"></div>
    </nav>
    <router-view />
  </div>
</template>




<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Header',
  setup() {
    const isMenuOpen = ref(false);

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    return {
      isMenuOpen,
      toggleMenu,
    };
  },
});
</script>

<style scoped>
@import url(../style/vars.css);
@import url(../style/view/general.css);

.nav-container {
  width: 100%;
  margin: 0;
}

nav {
  display: flex;
  background-color: var(--dbhubs-light);
  height: 4rem;
  min-height: 2.6rem;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
}
#logo-container {
  flex:1;
}

#logo-text {
  height: 2rem;
  margin-left: 2rem;
  /* margin-top: 0.5rem; */
}

.link-container-wrapper {
  display: flex;
  justify-content: center; /* Center the links within their container */
  align-items: center;
  font-size: 1.5rem;
  flex-grow: 1;
}

.link-container {
  margin: 0 1rem;
}

.separator {
  margin: 0 0.5rem;
}

.nav-link {
  text-decoration: none !important;
  color: #666;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  right: 1rem;
}

.flex-spacer {
  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    /* height: auto; */
    padding: 0.5rem;
    height: 1.5rem;
  }

  #logo-container {
    width: 100%;
    justify-content: space-between;
    margin-top: 0.3rem;
  }

/* Initial state for the mobile menu */
.link-container-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--dbhubs-light);
  text-align: center;
  margin-top: 1rem;

  margin-left: auto;
  padding: 0 1em;
  width: auto;
  border-radius: 0.5rem;

  /* Transition properties */
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  transform:scaleY(0);
  transform-origin:0 0;
}

/* When the menu is toggled on */
.link-container-wrapper.show-menu {
  max-height: 1000px;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 8px;
  transform:scaleY(1);
}

  .link-container {
    margin: 0.5rem 0;
    width: 100%;
  }

  .separator {
    display: none;
  }

  .menu-toggle {
    display: flex;
  }
}

@media (max-width: 480px) {
  .link-container-wrapper {
    font-size: 1.25rem;
  }
}

/* These address the nav link on which respective page you're on */
.router-link-active {
  color: black;
}
.nav-link:not(.router-link-active):hover {
  color: var(--dbhubs-red) !important;
}


.router-link-exact-active {}

</style>
