<template>
  <Header> </Header>
  <Footer></footer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

const params = new URLSearchParams(window.location.search);
const affiliate = params.get('affiliate');

affiliate && localStorage.setItem('affiliate', affiliate);

@Options({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {

}
</script>

<style>
@import url(style/vars.css);

#app {
  font-family: Righteous;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  cursor: default;
  caret-color: transparent;
  -webkit-user-drag: none;
}

body {

  margin: 0;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
